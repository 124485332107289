import React from 'react';
import { Link } from 'react-router-dom';
import subleasyLogo from '../images/subleasy.png';
import './Subleasy.css'; 

const Subleasy = () => {
  return (
    <div className="subleasy-container">
      <div className="hero-section">
        <img src={subleasyLogo} alt="Subleasy Logo" className="subleasy-logo" />
        <h1>Subleasy</h1>
        <p className="tagline">Find and list apartment subleases easily.</p>
        {/* <button className="cta-button">Download Now</button> */}
        <Link to="/subleasy/privacypolicy" className="privacy-policy-link">
          Privacy Policy
        </Link>
      </div>
      <div className="subleasy-content">
        <h2>Subleasing Made Easy</h2>
        <div className="features-grid">
          <div className="feature-card">
            <h3>Fast & Simple</h3>
            <p>List or find subleases in minutes</p>
          </div>
          <div className="feature-card">
            <h3>Verified Users</h3>
            <p>Connect with trusted members</p>
          </div>
          <div className="feature-card">
            <h3>Secure Messaging</h3>
            <p>Communicate safely in-app</p>
          </div>
          <div className="feature-card">
            <h3>No Hidden Fees</h3>
            <p>Transparent pricing always</p>
          </div>
        </div>
        <h2>Subleasy - Subleasing Made Easy</h2>
        <p>Skip the hassle of traditional subleasing. Whether you're listing your space or searching for your next temporary home, Subleasy simplifies the entire process into just a few taps.</p>
        <p>Create attractive listings in minutes, connect with verified users, and manage everything through secure in-app messaging. Browse authentic sublease opportunities, filter by your preferences, and find your perfect match instantly. Our smart matching system ensures you connect with the right people, while built-in safety features give you peace of mind.</p>
        <p>With Subleasy, you'll love how we've made subleasing simple:</p>
        <ul>
          <li>Lightning-fast listing and browsing</li>
          <li>Verified users and authentic listings</li>
          <li>Secure in-app messaging</li>
          <li>Smart matching system</li>
          <li>No hidden fees</li>
          <li>Real-time notifications</li>
          <li>Responsive support team</li>
        </ul>
        <p>Perfect for students, professionals, travel enthusiasts, or anyone seeking flexible living solutions. Join thousands who've discovered the easiest way to handle subleasing.</p>
        <p><strong>Download Subleasy today and experience subleasing made easy!</strong></p>
      </div>
    </div>
  );
};

export default Subleasy;