import React from 'react';
import { Link } from 'react-router-dom';
import './PrivacyPolicy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <nav className="policy-nav">
        <Link to="/subleasy" className="back-button">
          ← Back to Subleasy
        </Link>
      </nav>
      <div className="policy-header">
        <h1>Privacy Policy</h1>
        <p><em>Last Updated: November 29, 2024</em></p>
      </div>
      <div className="policy-sections">
        <div className="policy-card">
          <h2>Introduction</h2>
          <p>Welcome to Subleasy. We respect your privacy and are committed to protecting your personal information. This privacy policy explains how we collect, use, and safeguard your data when you use our mobile application ("Subleasy" or "App").</p>
        </div>
        <div className="policy-card">
          <h2>Information We Collect</h2>
          <h3>Essential Account Information:</h3>
          <ul>
            <li>Name</li>
            <li>Email address</li>
          </ul>
          <h3>Optional Listing Information (only if you choose to create a listing):</h3>
          <ul>
            <li>Property address</li>
            <li>Property photos</li>
            <li>Property description</li>
            <li>Other listing details you choose to provide</li>
          </ul>
        </div>
        <div className="policy-card">
          <h2>How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Create and manage your Subleasy account</li>
            <li>Enable communication between users</li>
            <li>Display property listings (if you choose to create them)</li>
            <li>Improve our services</li>
            <li>Ensure platform security</li>
            <li>Communicate important updates about our service</li>
          </ul>
        </div>
        <div className="policy-card">
          <h2>Data Storage and Security</h2>
          <p>We use Firebase, a service provided by Google, to store your data securely. We implement appropriate security measures to protect your personal information from unauthorized access, alteration, or disclosure.</p>
        </div>
        <div className="policy-card">
          <h2>Data Sharing and Disclosure</h2>
          <p>We do not sell your personal information to third parties. Your information is only visible to other users as necessary for the app's functionality:</p>
          <ul>
            <li>Your name is visible to other users</li>
            <li>Listing information is publicly visible within the app</li>
            <li>Your email address is not publicly displayed</li>
          </ul>
        </div>
        <div className="policy-card">
          <h2>Your Rights and Choices</h2>
          <p>You can:</p>
          <ul>
            <li>Access and update your account information at any time</li>
            <li>Delete your listings at any time</li>
            <li>Request deletion of your account and associated data</li>
            <li>Opt-out of non-essential communications</li>
          </ul>
        </div>
        <div className="policy-card">
          <h2>Data Retention</h2>
          <p>We retain your data only as long as necessary to provide our services. If you delete your account, we will delete your personal information from our systems.</p>
        </div>
        <div className="policy-card">
          <h2>Listings Management</h2>
          <ul>
            <li>You can create, edit, or delete your listings at any time</li>
            <li>Deleted listings are permanently removed from our platform</li>
            <li>Property information associated with deleted listings are also removed</li>
          </ul>
        </div>
        <div className="policy-card">
          <h2>Children's Privacy</h2>
          <p>Subleasy is not intended for use by individuals under the age of 18. We do not knowingly collect information from children.</p>
        </div>
        <div className="policy-card">
          <h2>Changes to This Policy</h2>
          <p>We may update this privacy policy from time to time. We will notify you of any significant changes through the app or via email.</p>
        </div>
        <div className="policy-card">
          <h2>Consent</h2>
          <p>By using Subleasy, you consent to this privacy policy and our processing of your information as described above.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;