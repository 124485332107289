import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent, { timelineContentClasses } from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import chevron from "../images/chevron.png";
import aws from "../images/aws.png";
import lsu from "../images/lsu.png";

function AlternateTimeline() {
  return (
    <Timeline
      position="alternate"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        [`& .${timelineContentClasses.root}`]: {
          flex: -0.2,
        },
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ color: "white" }}>
          <img src={lsu} alt="testing logo" style={{ width: "40px" }} />
          <h2>
            Undergraduate Researcher,
            <a href="https://www.aisx-lab.com/">AISx</a>
          </h2>
          <li>
            Led the development of LAS and DLIS file scrapers using Python,
            adhering to Agile methodology and industry best practices.
          </li>
          <li>
            Demonstrated exceptional problem-solving skills by extracting
            comprehensive DLIS file information, enhancing the company’s data
            processing capabilities.
          </li>
          <li>
            Innovated tools for accelerated data integration, giving Chevron a
            competitive edge in exploration activities.
          </li>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ color: "white" }}>
          <img src={aws} alt="testing logo" style={{ width: "50px" }} />
          <h2>
            Cloud Consultant,
            <a href="https://www.aisx-lab.com/">AWS</a>
          </h2>
          <li>
            Led the development of LAS and DLIS file scrapers using Python,
            adhering to Agile methodology and industry best practices.
          </li>
          <li>
            Demonstrated exceptional problem-solving skills by extracting
            comprehensive DLIS file information, enhancing the company’s data
            processing capabilities.
          </li>
          <li>
            Innovated tools for accelerated data integration, giving Chevron a
            competitive edge in exploration activities.
          </li>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ color: "white" }}>
          <img src={lsu} alt="testing logo" style={{ width: "50px" }} />
          <h2>
            Undergraduate Researcher,
            <a href="https://csc.lsu.edu/~chenwang/">MIST</a>
          </h2>
          <li>
            Led the development of LAS and DLIS file scrapers using Python,
            adhering to Agile methodology and industry best practices.
          </li>
          <li>
            Demonstrated exceptional problem-solving skills by extracting
            comprehensive DLIS file information, enhancing the company’s data
            processing capabilities.
          </li>
          <li>
            Innovated tools for accelerated data integration, giving Chevron a
            competitive edge in exploration activities.
          </li>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent style={{ color: "white" }}>
          <img src={chevron} alt="testing logo" style={{ width: "50px" }} />
          <h2>
            Software Enginner,
            <a href="https://www.chevron.com/">Chevron</a>
          </h2>
          <li>
            Led the development of LAS and DLIS file scrapers using Python,
            adhering to Agile methodology and industry best practices.
          </li>
          <li>
            Demonstrated exceptional problem-solving skills by extracting
            comprehensive DLIS file information, enhancing the company’s data
            processing capabilities.
          </li>
          <li>
            Innovated tools for accelerated data integration, giving Chevron a
            competitive edge in exploration activities.
          </li>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot />
        </TimelineSeparator>
        <TimelineContent style={{ color: "white" }}>
          <img src={chevron} alt="testing logo" style={{ width: "50px" }} />
          <h2>
            Software Enginner,
            <a href="https://www.chevron.com/">Chevron</a>
          </h2>
          <li>
            Led the development of LAS and DLIS file scrapers using Python,
            adhering to Agile methodology and industry best practices.
          </li>
          <li>
            Demonstrated exceptional problem-solving skills by extracting
            comprehensive DLIS file information, enhancing the company’s data
            processing capabilities.
          </li>
          <li>
            Innovated tools for accelerated data integration, giving Chevron a
            competitive edge in exploration activities.
          </li>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}

export default AlternateTimeline;
